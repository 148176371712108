import React, {useEffect, useState} from "react";
import {Proposal} from "../../models/proposal/Proposal";
import {ProposalsRepository} from "../../repositories/ProposalsRepository";
import {useAuth} from "react-oidc-context";
import {Box, Button, Checkbox, Container, Paper, Typography} from "@mui/material";
import {DataGrid, GridRowParams} from "@mui/x-data-grid";
import LoadingIndicator from "../../components/LoadingIndicator";
import {useNavigate} from "react-router-dom";
import {AuthHelpers, UserRole} from "../../Utils/AuthHelpers";

const AllResultsList = () => {
    const auth = useAuth();
    const repo = new ProposalsRepository(auth);
    const [proposals, setProposals] = useState<Proposal[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!AuthHelpers.isUserInrole(auth, UserRole.SuperAdmin)) {
            return;
        }
        setIsLoading(true);
        getProposals().catch(console.error);
    }, []);

    const getProposals = async () => {
        const proposals = await repo.getAllProposals();
        if (proposals) {
            setProposals(proposals);
        }
        setIsLoading(false);
    }
    
    if (!AuthHelpers.isUserInrole(auth, UserRole.SuperAdmin)) {
        return <></>;
    }

    return (<>
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 3, bgcolor: "background.paper" }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography variant="h5" sx={{ ml: 1 }} color="secondary">
                        Voorstellen
                    </Typography>
                </Box>
                <DataGrid
                    rows={proposals}
                    getRowId={(f: Proposal) => f.uniqueId ?? f.id}
                    rowSelection={true}
                    columns={[
                        { field: "uniqueId", headerName: "Id" },
                        { field: "userId", headerName: "User Id" },
                        { field: "submitDate", headerName: "submitDate", minWidth: 175 },
                        { field: "finishedDate", headerName: "finishedDate", minWidth: 175 },
                        { field: "title", headerName: "Omschrijving", minWidth: 200 },
                    ]}
                />
                <LoadingIndicator show={isLoading}/>
            </Paper>
        </Container>
    </>);
}

export default AllResultsList;
import React, {useEffect, useState} from "react";
import {Proposal} from "../../models/proposal/Proposal";
import {ProposalsRepository} from "../../repositories/ProposalsRepository";
import {useAuth} from "react-oidc-context";
import {Box, Button, Checkbox, Container, Paper, Typography} from "@mui/material";
import {DataGrid, GridRowParams} from "@mui/x-data-grid";
import LoadingIndicator from "../../components/LoadingIndicator";
import {useNavigate} from "react-router-dom";

const ResultsList = () => {
    const auth = useAuth();
    const repo = new ProposalsRepository(auth);
    const [proposals, setProposals] = useState<Proposal[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        setIsLoading(true);
        getProposals().catch(console.error);
    }, []);
    
    const getProposals = async () => {
        const proposals = await repo.getProposals();
        if (proposals) {
            setProposals(proposals);
        }
        setIsLoading(false);
    }
    
    const rowClick = (params: GridRowParams) => {
        navigate({
            pathname: `/proposals/${params.id}`,
        });
    };
    
    return (<>
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 3, bgcolor: "background.paper" }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography variant="h5" sx={{ ml: 1 }} color="secondary">
                        Voorstellen
                    </Typography>
                </Box>
                <DataGrid
                    rows={proposals}
                    getRowId={(f: Proposal) => f.uniqueId ?? f.id}
                    rowSelection={true}
                    onRowClick={rowClick}
                    columns={[
                        // { field: "id", headerName: "ID", minWidth: 50 },
                        { field: "title", headerName: "Omschrijving", minWidth: 500 },
                    ]}
                />
                <LoadingIndicator show={isLoading}/>
            </Paper>
        </Container>
    </>);
}

export default ResultsList;